var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('div',{staticClass:"form-row"},[_c('sgv-input-date',{staticClass:"col-12",attrs:{"label":"วันที่","validations":[
        {text: 'required!', value: !_vm.$v.createdDate.required && _vm.$v.createdDate.$dirty}
      ]},model:{value:(_vm.createdDate),callback:function ($$v) {_vm.createdDate=$$v},expression:"createdDate"}}),_c('sgv-input-radio',{staticClass:"col-12",attrs:{"label":"(คะแนน 1-5) โอกาส","options":_vm.probabilityOptions,"select":"value","inline":"","validations":[
        {text: 'required!', value: !_vm.$v.formData.options.probabilityPoint.required && _vm.$v.formData.$dirty},
        {text: 'จำนวนไม่อยู่ในช่วง', value: !_vm.$v.formData.options.probabilityPoint.between && _vm.$v.formData.$dirty}
      ]},scopedSlots:_vm._u([{key:"default",fn:function(option){return [_vm._v(" "+_vm._s(option.text)+" ")]}}]),model:{value:(_vm.formData.options.probabilityPoint),callback:function ($$v) {_vm.$set(_vm.formData.options, "probabilityPoint", $$v)},expression:"formData.options.probabilityPoint"}}),_c('sgv-input-radio',{staticClass:"col-12",attrs:{"label":"(คะแนน 1-5) โอกาสในการพัฒนา","options":_vm.probabilityChanceOptions,"select":"value","inline":"","validations":[
        {text: 'required!', value: !_vm.$v.formData.options.probabilityChancePoint.required && _vm.$v.formData.$dirty},
        {text: 'จำนวนไม่อยู่ในช่วง', value: !_vm.$v.formData.options.probabilityChancePoint.between && _vm.$v.formData.$dirty}
      ]},scopedSlots:_vm._u([{key:"default",fn:function(option){return [_vm._v(" "+_vm._s(option.text)+" ")]}}]),model:{value:(_vm.formData.options.probabilityChancePoint),callback:function ($$v) {_vm.$set(_vm.formData.options, "probabilityChancePoint", $$v)},expression:"formData.options.probabilityChancePoint"}}),_c('sgv-input-textarea',{staticClass:"col-12",attrs:{"label":"มาตรการควบคุม"},model:{value:(_vm.formData.options.revisionPlan),callback:function ($$v) {_vm.$set(_vm.formData.options, "revisionPlan", $$v)},expression:"formData.options.revisionPlan"}}),_c('sgv-input-textarea',{staticClass:"col-12",attrs:{"label":"อนุมัติ/ไม่อนุมัติ"},model:{value:(_vm.formData.options.remark),callback:function ($$v) {_vm.$set(_vm.formData.options, "remark", $$v)},expression:"formData.options.remark"}})],1),_c('button',{staticClass:"btn btn-success",on:{"click":_vm.createLog}},[_vm._v(" เพิ่ม ")])])}
var staticRenderFns = []

export { render, staticRenderFns }