<template>
  <div class="">
    <sgv-table
      :items="items"
      :headers="headers"
      :filter.sync="filter"
      :toolbars="toolbars"
      :options.sync="options">

      <template slot="option" v-if="options.toolbar === 'other'">
        <div class="mb-3">
          <DetailLogItemInput
            :paperId="paperId"
            :paperType="paperType"
            :templateType="templateType"
            @created="$apollo.queries.logs.refetch()">
          </DetailLogItemInput>
        </div>
      </template>

      <template slot-scope="{item, hidden}">
        <tr>
          <td v-if="hidden.createdAt">
            <span
              class="px-1 pointer"
              @dblclick="destroyLog(item.id)"
              v-if="method === 'edit'">
              <fa icon="trash" class="text-danger"></fa>
            </span>
            {{item.createdAt | date}}
          </td>
          <td v-if="hidden.revisionPlan" class="pre-line">
            {{item.revisionPlan}}
          </td>
          <td v-if="hidden.remark" class="pre-line">
            {{item.remark}}
          </td>
        </tr>
        <tr>
          <td
            :colspan="options.headers.length"
            class="pt-0"
             style="border-top: none !important;">
            <ProgressBar
              :totalPoint="item.level.totalPoint"
              :totalLevel="item.level.totalLevel">
            </ProgressBar>
          </td>
        </tr>
      </template>
    </sgv-table>
  </div>
</template>

<script>
import DetailLogItemInput from './DetailLogItemInput.vue'
import ProgressBar from './ProgressBar.vue'
import {
  LIST_LOG,
  DESTROY_LOG
} from './graph'

export default {
  props: {
    paperId: {
      type: Number,
      required: true
    },
    paperType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    method: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      filter: {
        limit: null,
        offset: 0,
        order: null,
        params: null
      },
      options: {
        headers: ['createdAt', 'revisionPlan', 'remark'],
        column: null,
        search: null,
        toolbar: null,
      },
      logs: [],
    }
  },
  computed: {
    headers () {
      return [
        {text: 'วันที่', value: 'createdAt'},
        {text: 'มาตรการควบคุม', value: 'revisionPlan'},
        {text: 'อนุมัติ / ไม่อนุมัติ', value: 'remark'}
      ]
    },
    toolbars () {
      const arr = [
        {value: 'other', icon: 'plus', class: 'text-success'}
      ]

      return arr
    },
    items () {
      return this.logs.map(item => {
        return {
          id: item.id,
          createdAt: item.createdAt,
          createdUser: item.createdUser,
          level: {
            totalLevel: item.options.totalLevel,
            totalPoint: item.options.totalPoint
          },
          revisionPlan: item.options.revisionPlan,
          remark: item.options.remark
        }
      })
    }
  },
  apollo: {
    logs: {
      query () {
        return LIST_LOG(this.templateType)
      },
      variables () {
        return {
          paperType: this.paperType,
          paperId: this.paperId
        }
      },
      fetchPolicy: 'network-only'
    }
  },
  methods: {
    destroyLog (id) {
      this.$apollo.mutate({
        mutation: DESTROY_LOG(this.templateType),
        variables: {
          paperType: this.paperType,
          logId: id
        }
      })
      .then(() => {
        this.$toasted.global.success("ลบสำเร็จ")
        this.$apollo.queries.logs.refetch()
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
  },
  components: {
    DetailLogItemInput,
    ProgressBar
  }
}
</script>

<style lang="css">
</style>
