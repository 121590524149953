<template>
  <div class="">
    <div class="form-row">
      <sgv-input-date
        class="col-12"
        v-model="createdDate"
        label="วันที่"
        :validations="[
          {text: 'required!', value: !$v.createdDate.required && $v.createdDate.$dirty}
        ]">
      </sgv-input-date>

      <sgv-input-radio
        label="(คะแนน 1-5) โอกาส"
        class="col-12"
        :options="probabilityOptions"
        select="value"
        inline
        v-model="formData.options.probabilityPoint"
        :validations="[
          {text: 'required!', value: !$v.formData.options.probabilityPoint.required && $v.formData.$dirty},
          {text: 'จำนวนไม่อยู่ในช่วง', value: !$v.formData.options.probabilityPoint.between && $v.formData.$dirty}
        ]">
        <template slot-scope="option">
          {{option.text}}
        </template>
      </sgv-input-radio>

      <sgv-input-radio
        label="(คะแนน 1-5) โอกาสในการพัฒนา"
        class="col-12"
        :options="probabilityChanceOptions"
        select="value"
        inline
        v-model="formData.options.probabilityChancePoint"
        :validations="[
          {text: 'required!', value: !$v.formData.options.probabilityChancePoint.required && $v.formData.$dirty},
          {text: 'จำนวนไม่อยู่ในช่วง', value: !$v.formData.options.probabilityChancePoint.between && $v.formData.$dirty}
        ]">
        <template slot-scope="option">
          {{option.text}}
        </template>
      </sgv-input-radio>

      <sgv-input-textarea
        label="มาตรการควบคุม"
        v-model="formData.options.revisionPlan"
        class="col-12">
      </sgv-input-textarea>

      <sgv-input-textarea
        label="อนุมัติ/ไม่อนุมัติ"
        v-model="formData.options.remark"
        class="col-12">
      </sgv-input-textarea>
    </div>

    <button
      class="btn btn-success"
      @click="createLog">
      เพิ่ม
    </button>
  </div>

</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { CREATE_LOG } from './graph'

export default {
  props: {
    paperId: {
      type: Number,
      required: true
    },
    paperType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      probabilityOptions: [
        {text: '(1) ไม่มีความจำเป็นต่อการพัฒนาการทำงานองค์กร ไม่มีใครได้รับผลกระทบ', value: 1},
        {text: '(2) มีความจำเป็นต่อการพัฒนาน้อยมากจากระบบการทำงานเดิมที่มีอยู่แล้ว', value: 2},
        {text: '(3) ทำให้ระบบการทำงานดีขึ้นบ้าง หรือสะดวกมากขึ้น ก่อให้เกิดประโยชน์ต่อองค์กรพอสมควร', value: 3},
        {text: '(4) ทำให้ระบบการทำงานดีขึ้น เกิดประโยชน์มาก', value: 4},
        {text: '(5) มีความจำเป็นต่อองค์กรในการแข่งกับคู่แข่ง หรือตามนโยบายบริหาร', value: 5}
      ],
      probabilityChanceOptions: [
        {text: '(1) เป็นไปได้น้อยมาก ไม่มีนโยบาย และค่าใช้จ่ายสูง ไม่คุ้มค่า', value: 1},
        {text: '(2) เป็นไปได้น้อย ไม่มีนโยบาย และค่าใช้จ่ายสูง', value: 2},
        {text: '(3) มีความเป็นไปได้ โดยมีนโยบายแต่ต้องดูความคุ้มค่า', value: 3},
        {text: '(4) มีความเป็นไปได้สูง มีนโยบายชัดเจน เพื่อแข่งกับคู่แข่ง', value: 4},
        {text: '(5) มีความเป็นไปได้สูงมาก มีนโยบายชัดเจน', value: 5}
      ],
      formData: {
        options: {
          probabilityPoint: 0,
          probabilityChancePoint: 0,
          revisionPlan: '',
          remark: ''
        }
      },
      createdDate: ''
    }
  },
  validations: {
    createdDate: {required},
    formData: {
      options: {
        probabilityPoint: {
          required,
          between: function(point) {
            return point > 0 && point <= 5
          }
        },
        probabilityChancePoint: {
          required,
          between: function(point) {
            return point > 0 && point <= 5
          }
        }
      }
    }
  },
  methods: {
    createLog () {
      this.$v.$touch()
      if (this.$v.$invalid) return

      this.$apollo.mutate({
        mutation: CREATE_LOG(this.templateType),
        variables: {
          paperType: this.paperType,
          paperId: this.paperId,
          createdDate: this.createdDate,
          input: this.formData
        }
      })
      .then(() => {
        this.setDefault()
        this.$toasted.global.success("เพิ่มสำเร็จ")
        this.$emit('created', null)
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    setDefault () {
      this.$v.$reset()

      this.createdDate = ''
      this.formData.options.probabilityPoint = 0
      this.formData.options.probabilityChancePoint = 0
      this.formData.options.revisionPlan = ''
      this.formData.options.remark = ''
    }
  },
  created () {
    this.setDefault()
  }
}
</script>

<style lang="css" scoped>
</style>
